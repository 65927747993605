<template>
  <div>
    <PanelNavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import PanelNavBar from '../components/partials/PanelNavBar.vue';
import Footer from '../components/partials/Footer.vue';
/*import CreatorUpdateData from '../components/partials/CreatorUpdateData.vue';*/

export default {
    components: {
        PanelNavBar,
        Footer,
        /*CreatorUpdateData*/
    },
    data () {
        return {
            modal: false
        }
    },
    metaInfo: {
        titleTemplate: '%s - Harmony Fan',
        title: 'Panel'
    },
    watch: {
        $route: {
           /* handler() {
                const user = JSON.parse(localStorage.getItem('user'));
                const admin = JSON.parse(localStorage.getItem('impersonate_admin'));

                if (!admin){
                    if (user) {
                        if (
                            user.role.id === 3 && !user.creator_type && !user.gender && !user.age 
                            || user.role.id === 3 && !user.creator_type
                        ) {
                            this.modal = true;
                        }
                    }
                }
            },
            immediate: true */
        }
    }
};
</script>

<style scoped>
    #app {
        background: #f1eeff !important;
    }
</style>